<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <h2 class="brand-text text-primary ml-1">
          <myegrn-logo />
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Вход
          </b-card-title>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{name:'auth-forgot-password'}">
                    <small>Забыли пароль?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Запомнить меня
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid || !loading"
              >
                <b-spinner
                  v-if="!loading"
                  small
                />
                Войти
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <b-link :to="{name:'auth-register'}">
              <span>&nbsp;Зарегистрироваться</span>
            </b-link>
          </b-card-text>

        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import MyegrnLogo from '@core/layouts/components/Logo.vue'
import {
  BSpinner, BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    MyegrnLogo,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      loading: true,
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    login() {
      const vm = this
      vm.loading = false
      setTimeout(() => {
        vm.$refs.loginForm.validate().then(success => {
          if (success) {
            useJwt.login({
              email: vm.userEmail,
              password: vm.password,
            })
              .then(response => {
                if (!response || !response.data || !response.data.Data || (response && response.data && response.data.Data && response.data.Data.error)) {
                  vm.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Ошибка авторизации',
                      icon: 'LoginCoffeeIcon',
                      variant: 'danger',
                      text: 'Не правильный логин или пароль',
                    },
                  })
                  vm.loading = true
                } else {
                // console.log('login responce', response)
                  const { userData } = response.data.Data
                  useJwt.setToken(response.data.Data.accessToken)
                  useJwt.setRefreshToken(response.data.Data.refreshToken)
                  localStorage.setItem('userDataTicks', new Date().getTime() + (24 * 60 * 10 * 1000)) // на 10 дней
                  localStorage.setItem('userData', JSON.stringify(userData))
                  vm.$ability.update(userData.ability)
                  store.commit('app-ecommerce/SET_BALANCE', userData.balance)
                  // ? This is just for demo purpose as well.
                  // ? Because we are showing eCommerce app's cart items count in navbar
                  // vm.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')).length : 0)

                  // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                  vm.$router.replace(getHomeRouteForLoggedInUser(userData.role ? userData.role : ''))
                    .then(() => {
                      vm.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: 'Добро пожаловать в архив МойЕГРН',
                          icon: 'CoffeeIcon',
                          variant: 'success',
                          text: 'Вы успешно вошли в архив МойЕГРН. Теперь вы можете начать делиться файлами и зарабатывать!',
                        },
                      })
                    })
                    .catch(error => {
                      vm.$refs.loginForm.setErrors(error.response.data.error)
                      vm.loading = false
                    })
                }
              }).catch(error => {
                let err = 'Вы ввели не верные данные'
                if (error.toJSON().message === 'Network Error') {
                  err = 'Cервер не доступен или проблемы с интернетом'
                }
                vm.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Ошибка',
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: `${err}`,
                  },
                })
                vm.loading = true
              })
          }
        })
      }, 0)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
